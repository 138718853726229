import { router } from "@inertiajs/react";
import React, { createContext, useContext, useEffect, useState } from "react";

import { TODO } from "@/types";

const AppContext = createContext<TODO>(null!);

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(() => {
    const storedValue = localStorage.getItem("mobileSidebarOpen");
    if (storedValue === null) {
      localStorage.setItem("mobileSidebarOpen", "false");
      return false;
    }
    return storedValue === "true";
  });
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(() => {
    const storedValue = localStorage.getItem("desktopSidebarOpen");
    if (storedValue === null) {
      localStorage.setItem("desktopSidebarOpen", "true");
      return true;
    }
    return storedValue === "true";
  });

  const [canToggleSidebar, setCanToggleSidebar] = useState(
    !window.location.pathname.startsWith("/settings"),
  );

  const toggleMobileSidebar = () => {
    const newValue = !mobileSidebarOpen;
    setMobileSidebarOpen(newValue);
    localStorage.setItem("mobileSidebarOpen", String(newValue));
  };

  const toggleDesktopSidebar = () => {
    const newValue = !desktopSidebarOpen;
    setDesktopSidebarOpen(newValue);
    localStorage.setItem("desktopSidebarOpen", String(newValue));
  };

  useEffect(() => {
    return router.on("navigate", (event) => {
      const pathname = event.detail.page.url;
      if (pathname.startsWith("/settings")) {
        setCanToggleSidebar(false);
        setMobileSidebarOpen(false);
        setDesktopSidebarOpen(true);
      } else {
        setCanToggleSidebar(true);
        setMobileSidebarOpen(
          localStorage.getItem("mobileSidebarOpen") === "true",
        );
        setDesktopSidebarOpen(
          localStorage.getItem("desktopSidebarOpen") === "true",
        );
      }
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        mobileSidebarOpen,
        setMobileSidebarOpen,
        desktopSidebarOpen,
        canToggleSidebar,
        setDesktopSidebarOpen,
        toggleMobileSidebar,
        toggleDesktopSidebar,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within a AppProvider");
  }
  return context;
};
